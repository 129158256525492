<script>
export default {
  props: {
    cardApplication: {
      type: Object,
      default: () => {},
    },
  },
  emits:['print'],
  data() {
    return {
      type: 'youth_card_shipping',
    };
  },
  methods: {
    print(item) {
        this.$emit('print', this.type, item);
    }
  }
};
</script>

<template>
  <div>
    <a v-if="cardApplication?.revised" href="javascript:void(0);"
        class="mr-3 text-info" v-b-tooltip.hover title="Imprimir carta de envío"
        @click="print(cardApplication)">
        <i class="mdi mdi-email-open-multiple-outline font-size-18"></i>
    </a>
    <a v-else href="javascript:void(0);"
        class="mr-3 text-secondary" v-b-tooltip.hover title="Debe marcar la solicitud como revisada, para imprimir la carta de envío">
        <i class="mdi mdi-email-open-multiple-outline font-size-18"></i>
    </a>
  </div>
</template>